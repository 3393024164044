<template>
  <div class="news-details">
    <div>
      <div class="news-content">
        <h1>{{ news.imgTitle }}</h1>
        <div class="news-tips">
          <div>{{ formattingDate(news.imgTime) }}</div>
          <div>来源：{{ news.imgSource }}</div>
          <!-- 暂时不做 -->
          <!-- <div><img src="@/assets/image/news-details-eye.png" alt="" />800</div> -->
        </div>
        <div v-html="news.imgDetails"></div>
      </div>
      <div class="related-news">
        <h2>相关新闻</h2>
        <ul>
          <template v-for="(item, index) in aboutNews" :key="`about-news-${item.id}`">
            <div v-if="!index" class="top-news" @click="jumpPage('NewsDetails', item.id)">
              <div>
                <img :src="imgBaseUrl + item.imgPath" :alt="item.imgTitle" />
              </div>
              <h3 class="ellipsis">{{ item.imgTitle }}</h3>
              <div class="ellipsis-3">{{ item.imgSubtitle }}</div>
            </div>
            <li v-else class="ellipsis" @click="jumpPage('NewsDetails', item.id)">{{ item.imgTitle }}</li>
          </template>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts" src="./index.ts"></script>

<style lang="scss" scoped>
.news-details {
  background-color: #f0f3f5;
  padding-top: 22px;
  padding-bottom: 40px;
  img {
    width: 100%;
  }
  > div {
    width: $effectiveArea;
    margin: 0 auto;
    display: flex;
  }
  .news-content,
  .related-news {
    background-color: #fff;
  }
  .news-content {
    flex: 1;
    margin-right: 10px;
    padding: 20px 40px;
    h1 {
      font-size: 24px;
      font-weight: bold;
    }
    p {
      text-indent: 2em;
    }
    .news-tips {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: bold;
      color: #9d9ea3;
      border-bottom: 1px solid #e5e5e5;
      padding-bottom: 10px;
      > div {
        display: flex;
        align-items: center;
        & + div {
          margin-left: 55px;
          img {
            width: auto;
            height: 10px;
            margin-right: 6px;
          }
        }
      }
    }
  }
  .related-news {
    width: 364px;
    padding: 20px 20px 20px 30px;
    font-size: 14px;
    h2 {
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 16px;
    }
    h3 {
      font-size: 16px;
      font-weight: bold;
      margin: 12px 0;
    }
    ul {
      padding-left: 0;
    }
    li {
      margin: 16px 0;
      list-style-type: none;
      background: url("~@/assets/image/news-details-triangle.png") no-repeat 0px 4px;
      background-size: 9px 12px;
      padding-left: 20px;
      transition-duration: 0.3s;
      &:hover {
        color: $focusFontColor;
      }
    }
    li,
    .top-news {
      cursor: pointer;
      transition-duration: 0.3s;
      > :first-child {
        height: 232px;
        position: relative;
        img {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &:hover {
        color: $focusFontColor;
      }
    }
  }
}
</style>
