import { defineComponent, reactive, toRefs, watch } from "vue";
import { getByUrl } from "@/utils/http";
import { useRoute, useRouter } from "vue-router";
import { mapGetters } from "vuex";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

interface Data {
  aboutNews: Array<{
    id: number;
    imgDetails: null;
    imgPath: null;
    imgSource: null;
    imgSubtitle: null;
    imgTime: null;
    imgTitle: string;
    isHomePage: null;
    newsId: null;
  }>;
  news: Array<{
    id: number;
    imgDetails: string;
    imgPath: string;
    imgSource: string;
    imgSubtitle: string;
    imgTime: string;
    imgTitle: string;
    isHomePage: 0;
    newsId: string;
  }>;
}

export default defineComponent({
  name: "NewsDetails",
  computed: {
    ...mapGetters(["imgBaseUrl"])
  },
  setup() {
    const route = useRoute();
    const { newsId } = route.params;
    const router = useRouter();
    const data: Data = reactive({
      aboutNews: [],
      news: [],
      formattingDate(date: string) {
        return dayjs(date, "YYYY-MM-DD").format("YYYY年MM月DD日");
      },
      jumpPage: (name: string, newsId: number) => {
        router.push({ name, params: { newsId } });
      }
    });
    const queryNewsDetails = (newsId: string | string[]) => {
      getByUrl("/news/getNewsDetails", { newsId }).then(res => {
        const { aboutNews, news } = res;
        data.aboutNews = aboutNews;
        data.news = news;
      });
    };
    queryNewsDetails(newsId);
    watch(
      () => route.params,
      ({ newsId }) => {
        if (typeof newsId === "undefined" || !newsId || newsId === "undefined") return;
        queryNewsDetails(newsId);
      }
    );
    return {
      ...toRefs(data)
    };
  }
});
